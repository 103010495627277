* {
  font-family: Avenir, Arial, Helvetica, sans-serif !important;

  body {
    overflow: hidden;
    #app {
      overflow: hidden;
      height: calc(100vh - var(--topbar-height));
      margin-top: var(--topbar-height);
    }
  }
}
