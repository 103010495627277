.tab-element {
  &:hover, &.selected {
    background-color: var(--color-grey-800);
  }

  .title {
    color: var(--color-lighter-grey);
    font-size: 14px;
    font-weight: 900;
    font-family: Avenir;
  }
}